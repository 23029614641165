import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import useSettingsSelector from "./../../store/selectors/settings";

const Gallery = (props) => {

	const { RECIEVER } = useSettingsSelector();
	const {content} = props;
	const GalleryItem = ({item}) => {
		return (
			<Card sx={{ width: "100%", backgroundColor: RECIEVER.BACKGROUND_COLOR, borderRadius: "12px" }}>
				<CardContent>
					<Typography variant="body2" color="text.secondary">
						{item.description}
					</Typography>
				</CardContent>
				<CardMedia
					sx={{ 
						height: 140,
						width: "90%",
    					margin: "0 auto"
					}}
					image={item.image}
					title="gallery item"
				/>
				<CardActions>
					<Button
						component={Link}
						to={item.buttonURL}
						target="_blank"
						size="small"
						variant="outlined"
						sx={{
							borderColor: "lightgrey", 
							color: "black",
							borderRadius: "12px",
							width: "90%",
							margin: "0 auto"
						}}
					>
						{item.buttonName}
					</Button>
				</CardActions>
			</Card>
		);
	};
	return (
		<>
			<Stack direction="column" spacing={1} sx={{width: "80%", margin: "0 auto", paddingBottom: "10px", overflowX: "scroll"}}>
				{content.items.map((item, idx) => {
					return <GalleryItem item={item} />;
				})}
			</Stack>
		</>
	);
};

export default Gallery;