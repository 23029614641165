import React from "react";
import { styled } from "@mui/system";
import { get } from "lodash";
import {
	Box,
	Stack,
	Typography,
	Avatar
} from "@mui/material";
import useSettingsSelector from "../../store/selectors/settings";
import { Message, MessageAndButtons, Gallery, DataCollector } from "./../../components/widgets";
import { SPARKLE_ICON } from "../../constants"; 

const SparkleAvatar = styled(Avatar)({
	border: "2px solid white",
	backgroundColor: "#F5F8FD",
	height: "36px",
	width: "36px",
	border: "2px solid white",
});

export default function Reciever(props) {
	const {
		reply,
		agentId,
		tenantId,
		agents,
		contact,
		query,
		handleRequest,
		widget
	} = props;
	const { RECIEVER } = useSettingsSelector();
	const agent = Array.isArray(agents) ? agents.find(el => el.frontEggId === agentId) : null;
	const type = get(reply, "type", null);
	const AIAgentName = get(widget, "name", "AI agent")
	const _contact = contact || reply.contact;
	
	let ReplyContent = null;

	switch (type) {
	case 'msg_and_buttons':
		ReplyContent = <MessageAndButtons content={reply} handleRequest={handleRequest}/>;
		break;
	case 'msg':
		ReplyContent = <Message content={reply} />;
		break;
	case 'assistant':
		ReplyContent = <Message content={reply} />;
		break;
	case 'gallery':
		ReplyContent = <Gallery content={reply} />;
		break;
	case 'collector':
		ReplyContent = <DataCollector 
			content={reply}
			tenantId={tenantId}
			contact={_contact}
			query={query}
			handleRequest={handleRequest}
		/>;
		break;   
	default:
		console.warn(`Unrecognized type ${type} of content.`);
	}

	const getAIAgentNameStr = (name) => {
		return name === "AI agent" ? "AI agent" : name + " (AI agent)"
	}
	return (
		<>
			{reply && (
				<Stack className="reciever-ui" sx={{margin: 0}} direction="column" spacing={1}>
					<div className="receiver-info">
						{agent && (
							<Avatar
								className="reciever-avatar-container"
								src={agent.profileURL}
								alt={`${agent.name} agent`}
							/>
						)}
						{!agent && (
							<SparkleAvatar >{SPARKLE_ICON}</SparkleAvatar>
						)}
						<Typography variant="body2">{agent ? agent.name : getAIAgentNameStr(AIAgentName)}</Typography>
					</div>
					<div className="reciever-message-container">
						{ReplyContent}
					</div>
				</Stack>
			)}
		</>
	);
}
