import React, { useState, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./../pages/Landing";


const PrivateRoutes = (props) => {
	return (
		<Routes>
			<Route path="/landing" element={<Landing {...props} />}></Route>
			<Route
				path="*"
				element={
					<div>
						<h2>404 Page not found</h2>
					</div>
				}
			/>
		</Routes>
	);
};

export default PrivateRoutes;