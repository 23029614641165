import React, { useContext, useMemo } from "react";

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { WEB_WIDGET_BUTTON_LOGO_SVG } from "../../constants";
import useSettingsSelector from "../../store/selectors/settings";

export default function LandingButton(props) {
	const {
	} = props;
	const { WEB_WIDGET_BUTTON } = useSettingsSelector();

	const CustomWebWidgetIconButton = useMemo(() =>
		(styled(IconButton)(({ theme }) => ({
			width: '60px',
			height: '60px',
			backgroundColor: WEB_WIDGET_BUTTON.BACKGROUND_COLOR,
			color: WEB_WIDGET_BUTTON.FONT_COLOR,
			'&:hover': {
				backgroundColor: WEB_WIDGET_BUTTON.BACKGROUND_COLOR,
				color: WEB_WIDGET_BUTTON.FONT_COLOR,
			},
		}))), [WEB_WIDGET_BUTTON]);
	return (
		<CustomWebWidgetIconButton
			id="intelSwiftWebWidgetButton"
			aria-label="IntelSwift">
			{WEB_WIDGET_BUTTON_LOGO_SVG}
		</CustomWebWidgetIconButton>
	);
};