var W3CWebSocket = require("websocket").w3cwebsocket;
// const dotenv = require('dotenv');

// dotenv.config();

class SocketClient {
	#ws = null;
	constructor() {
		this.initializeSocket();
	}
	getWidgetDomain(){
		const hostDomain = window.location.hostname;
		if(hostDomain.includes("stage")){
			return 'wss://stage-chat.intelswift.com';
		} else if (hostDomain.includes("localhost") || hostDomain.includes("127.0.0.1")){
			return 'ws://localhost:8000';
		}else {
			return 'wss://chat.intelswift.com';
		}
	}
	initializeSocket(){
		try{
			this.#ws = new W3CWebSocket(`${this.getWidgetDomain()}?origin=web-widget`, "echo-protocol");
			// Handle WebSocket connection error
			this.#ws.onerror = (error) => {
				if (error.code === 'ECONNREFUSED') {
					this.#ws.removeAllListeners();
					this.reconnect();
				}
			};
			// Handle WebSocket client connection open
			this.#ws.onopen = () => {};
			// Handle WebSocket client connection close
			this.#ws.onclose = () => {
				this.reconnect();
			};
		} catch (error) {
			console.error("@@ WEB WIDGET @@ socket exception  ", error);
		}
	}
	reconnect() {
		setTimeout(() => {
			this.initializeSocket();
		}, 2000);
	}
	sendContactId(contactId, tenantId) {
		if (this.#ws && this.#ws.readyState === W3CWebSocket.OPEN) {
			// Ensure the message is in JSON format
			const jsonMessage = JSON.stringify({
				topic: 'widget_pong',
				message: {
					contactId: contactId,
					tenantId: tenantId
				},
			});
			this.#ws.send(jsonMessage);
		} else {
			console.error('WebSocket is not open. Ready state: ', this.#ws ? this.#ws.readyState : 'undefined');
			this.reconnect();
		}
	}
	get client() {
		return this.#ws;
	}
}
export default SocketClient;
