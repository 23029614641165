import React from "react";
import Message from "./TextMessage";
import { Button, Stack } from "@mui/material";

const MessageAndButtons = (props) => {
	const {content, handleRequest} = props;
	return (
		<>
			<Message {...props}/>
			<Stack direction="column" spacing={1} sx={{width: "80%", margin: "10px auto 0"}}>
				{content.buttons.map((el, idx) => {
					return (
						<Button 
							key={`${el.text}${idx}`} 
							variant="outlined"
							sx={{
								borderColor: "lightgrey", 
								color: "black",
								borderRadius: "12px"
							}}
							onClick={()=>{handleRequest({
								callback_query: {
									data: el.callback_data
								}
							});}}
						>{el.text}</Button>
					);
				})}
			</Stack>
			
		</>
	);
};

export default MessageAndButtons;