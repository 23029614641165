import React, {useState, useEffect} from "react";
import axios from "axios";
import getBrowserFingerprint from "get-browser-fingerprint";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import { get } from "lodash";
import {
	Box,
	Stack,
	Card,
	CardContent,
	CardHeader,
	Avatar,
	IconButton,
} from "@mui/material";
import useSettingsSelector from './../../store/selectors/settings';
import Logo from "./../../assets/images/is_logo.png";
import Home from "./../Home";
import Chat from "./../Chat";
import {
	CHAT_ICON,
	HOME_ICON,
	INTELSWIFT_GRAY_LOGO,
} from './../../constants';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

const Landing = (props) => {
	const {botId, tenantId, widget} = props;
	const {
		HEADER,
		CONTENT,
		FOOTER,
		SUB_FOOTER
	} = useSettingsSelector();

	const [page, setPage] = useState("home");
	const [faq, setFaq] = useState([]);
	const [faqs, setFaqs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState(null);
	const [conversation, setConversation] = useState(null);
	const [agents, setAgents] = useState([]);
	const [records, setRecords] = useState([]);
	const [UUID, setUUID] = useState(null);

	const HOME_ICON_COLOR = HOME_ICON(page === "home" ? FOOTER.FONT_COLOR : FOOTER.FONT_UN_SELECTED_COLOR);
	const CHAT_ICON_COLOR = CHAT_ICON(page === "chat" ? FOOTER.FONT_COLOR : FOOTER.FONT_UN_SELECTED_COLOR); 
	const HEADER_BACKGROUND =  get(widget, "chat.header.backgroundColor", HEADER.BACKGROUND_COLOR);
	const TITLE =  get(widget, "chat.header.title", "");
	const BODY_BACKGROUND =  CONTENT.BACKGROUND_COLOR;
    

	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true },"*");
	};
	const handleFaq = (faq) => {
		setPage("chat");
		setFaq(faq);
	};
	const handleSocketUpdate = async (event, records) => {
		try {
			// Extracts necessary data (msg, conversationId, contactId, ticket, escalate, agentId) from the message
			const parsedMessage = JSON.parse(event.data);
			const { topic } = parsedMessage;
			if (topic === "reply_update") {
				const { contactId, content, agentId, isAssigned } = parsedMessage.message;
				if (contactId == UUID) {
					setRecords([...records, {query: null, reply: content}]);
				}
			}
			if (topic === "agents") {
				const { agents } = parsedMessage.message;
				// setAvatars(agents);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRequest = async (content) => {
		if(content.msg_query){
			setRecords([...records, {query: content.msg_query.text, reply: null}]);
		}
        
		return await axiosInstance.post(`/widgets/query`, {
			content: content,
			tenantId: tenantId,
			botId: botId,
			contactId: UUID,
			href: window.location.hostname 
		});
	};
	const initSessionAndGetContact = async (contactId, tenantId, botId) => {
		const response = await handleRequest({
			new_session: true
		});
		const _contact = get(response, "data.data", null);
		if(_contact){
			setContact(_contact);
		}
	};
	const fetchConversation = async (uuid, tenantId, botId) => {
		const response = await axiosInstance.get(`/conversations/details/${uuid}?botId=${botId}&tenantId=${tenantId}`);
		const _conversation = get(response, "data.data", null);
		if(_conversation){
			setConversation(_conversation);
			setRecords(_conversation.records);
		}
		setLoading(false);
	};
	const fetchAgents = async (tenantId) => {
		const response = await axiosInstance.get(`/agents?tenantId=${tenantId}`);
		const _agents = get(response, "data.data", [])?.filter(el => el.presence == 1);
		setAgents(_agents || []);
	};
	
	useEffect(() => {
		if (UUID && widget && tenantId && botId) {
			initSessionAndGetContact(UUID, tenantId, botId);
			fetchAgents(tenantId);
			fetchConversation(UUID, tenantId, botId);
		}
	}, [UUID, widget]);

	useEffect(()=>{
		if(widget){
			setFaqs(widget.faqs);
		}
	}, [widget]);
	useEffect(() => {
		const fingerprint = getBrowserFingerprint();
		setUUID(fingerprint);
	}, []);

	useEffect(() => {
		if(UUID){
			if (global.socket.client) {
				global.socket.client.addEventListener("message", (evt)=>{handleSocketUpdate(evt, records);});
			}
			return () => {
				if (global.socket.client) {
					global.socket.client.removeEventListener("message", (evt)=>{handleSocketUpdate(evt, records);});
				}
			};
		}
	}, [UUID, records]);
	return (
		<>
			<Box sx={{ width: '100%', }} id="wws">
				<Card className="widget-root">
					<CardHeader
						sx={{
							background: page === "home" ? `linear-gradient(45deg, #000000 0%, ${HEADER_BACKGROUND} 100%)` : 'white', 
							padding: "20px", 
							height: page === "home" ? "200px" : "35px",
							borderBottom: page === "home" ? "none" : "1px solid lightgrey",
						}}
						avatar={ page === "home" ? <Avatar alt="" src={Logo} sx={{width: "36px", height: "36px"}}/> : (
							<IconButton aria-label="back">
								<IoChevronBack onClick={()=>{
									setPage('home');
								}}/>
							</IconButton>
						)		
						}
						action={
							<IconButton aria-label="close">
							  <IoMdClose onClick={hideWidget}/>
							</IconButton>
						}
						titleTypographyProps={
							{
								color: "white",
								fontWeight: "600",
								fontSize: "27px",
								lineHeight: "32.4px"
							}
						}
						title={page === "home" ? TITLE : ""}
					/>
					<CardContent
						className = {page === "home" ? "home-content" : "chat-content"}
						sx={{
							overflowY: 'auto',
							position: 'relative',
							padding: '0 !important',
							overflowY: 'scroll',
							marginTop: page === "home" ? "-80px" : "0"
						}}>
						{page === "home" && (
							<>
								<Home
									{...props}
									faqs={faqs}
									agents={agents}
									setPage={setPage}
									handleFaq={handleFaq}
								/>
							</>
						)}
						{page === "chat" && (
							<>
								<Chat
									{...props}
									widget={widget}
									agents={agents}
									tenantId={tenantId}
									records={records}
									contact={contact}
									faq={faq}
									setFaq={setFaq}
									handleRequest={handleRequest}
								/>
							</>
						)}
					</CardContent>
					<Stack direction="column">
						<Box sx={{
							bgcolor: SUB_FOOTER.BACKGROUND_COLOR,
							padding: '16px !important',
						}}>
							<div style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
								{INTELSWIFT_GRAY_LOGO}
								<span style={{color: 'black'}}>Powered by <a href="https://intelswift.com/" style={{color: 'inherit'}} target='_blank' rel="noreferrer">IntelSwift</a></span>
							</div>
						</Box>
					</Stack>
				</Card>
			</Box>
			{/* <WidgetButton /> */}
		</>
	);
};

export default Landing;