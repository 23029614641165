import React from "react";
import Avatar from "@mui/material/Avatar";
import {Typography, Box} from "@mui/material";
import { get } from "lodash";
import useSettingsSelector from "../../store/selectors/settings";

// Generate the random color once and store it
const defaultAvatarColors = [
	"rgba(133, 174, 255, 1)",
	"rgba(98, 87, 255, 1)",
	"rgba(240, 180, 255, 1)",
	"rgba(191, 224, 255, 1)",
	"rgba(171, 212, 151, 1)",
	"rgba(217, 245, 184, 1)",
	"rgba(255, 244, 146, 1)",
	"rgba(255, 234, 221, 1)",
	"rgba(255, 221, 180, 1)",
	"rgba(255, 202, 123, 1)",
	"rgba(255, 146, 146, 1)",
	"rgba(193, 193, 193, 1)",
	"rgba(95, 95, 95, 1)"
];

// Function to generate a random color from the defaultAvatarColors array
const getRandomColor = () => {
	const index = Math.floor(Math.random() * defaultAvatarColors.length);
	return defaultAvatarColors[index];
};

// Function to calculate luminance of a given color
const getLuminance = (color) => {
	const rgb = color.match(/\d+/g).map(Number); // Extract the RGB values from the color string
	const [r, g, b] = rgb.map(value => value / 255); // Normalize the RGB values to the range [0, 1]
	const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Calculate the luminance
	return luminance;
};

// Store the random color in a variable
const senderAvatarColor = getRandomColor();
// Determine the text color based on the luminance of the avatar color
const textColor = getLuminance(senderAvatarColor) > 0.5 ? "black" : "white";

export default function Sender(props) {
	const {
		txt,
		contact
	} = props;
	const { SENDER } = useSettingsSelector();
	const name = contact?.meta['Full name']?.value || "Unknown sender";
	// Extract initials from the sender's name
	const nameParts = name?.split(" ");
	const avatarLetters = nameParts?.length > 1
		? `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`.toUpperCase()
		: name?.charAt(0).toUpperCase();

	return (<Box className="sender-ui" sx={{margin: 0}}>
		<div
			className="sender-message-container"
			style={{ backgroundColor: SENDER.BACKGROUND_COLOR }}> {/* Set the background color for the message container */}
			<p style={{ margin: "0px", color: SENDER.FONT_COLOR }}>
				{txt}
			</p>
		</div>
	</Box>
	);
}
