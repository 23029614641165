import React, {useState} from "react";
import { BrowserRouter } from "react-router-dom";
import { SettingsContextProvider } from "./store/settings";
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from "./store/auth";
import SocketClient from "./socket-client";
import {DefaultTheme} from "./assets/theme";
import ApplicationRoutes from "./routes";

global.socket = new SocketClient();

export default function App() {
	const [tenantId, setTenantId] = useState(null);
	const [botId, setBotId] = useState(null);
	const [host, setHost] = useState(null);

	const propsInterval = setInterval(widgetTimer, 1000);

	function widgetTimer() {
		const tenant = localStorage.getItem("tenantId");
		const bot = localStorage.getItem("botId");
		const hostURL = localStorage.getItem("host");

		if((tenant && tenant != "undefined") && (bot && bot != "undefined") && (hostURL && hostURL != "undefined")){
			clearInterval(propsInterval);
			setTenantId(tenant);
			setBotId(bot);
			setHost(hostURL);
		}
	}

	return (
		<>
			{tenantId &&  botId && host && (
				<AuthProvider>
					<ThemeProvider theme={DefaultTheme}>
						<SettingsContextProvider>
							<BrowserRouter>
								<ApplicationRoutes tenantId={tenantId} botId={botId} host={host}/>
							</BrowserRouter>
						</SettingsContextProvider>
					</ThemeProvider>
				</AuthProvider>
			)}
		</>
	);
}