import React from "react";
import { styled } from "@mui/system";
import {
	Avatar
} from "@mui/material";
import { SPARKLE_ICON } from "./../../constants";

const Avatars = styled("div")({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& > *:not(:first-child)": {
		marginLeft: -15
	},
});

// Custom styled avatar
const StyledAvatar = styled(Avatar)({
	border: "2px solid white", // Add a white border
	objectFit: "cover",
	height: "36px",
	width: "36px",

});

// Custom styled extra agents avatars
const MoreAvatar = styled(Avatar)({
	border: "2px solid white",
	backgroundColor: "rgb(233, 231, 255)",
	color: "black",
});

// Sparkle agent icon avatar constant
const SparkleAvatar = styled(Avatar)({
	border: "2px solid white",
	backgroundColor: "#F5F8FD",
	height: "36px",
	width: "36px",
	border: "2px solid white",
});


const AvatarStack = ({agents}) => {

	const renderAvatars = () => {
		// Create a copy of the avatars array to manipulate
		const avatarsWithSparkle = agents.slice();
		// Insert the sparkle avatar at the third position if there are 3 or more avatars
		if (avatarsWithSparkle.length >= 3) {
			avatarsWithSparkle.splice(2, 0, { profileURL: "sparkle" });
			// Add the sparkle avatar to the end if there are fewer than 3 avatars and no sparkle avatar is already present
		} else if (!avatarsWithSparkle.some(avatar => avatar.profileURL === "sparkle")) {
			avatarsWithSparkle.push({ profileURL: "sparkle" });
		}
		// Map over the first 4 avatars to create the avatar elements
		return avatarsWithSparkle.slice(0, 4).map((agent, index) => {
			// If the fourth avatar is being rendered and there are more than 3 avatars, display the extra count
			if (index === 3 && agent.length > 3) {
				return <MoreAvatar key={index}>+{agents.length - 3}</MoreAvatar>;
			}
			// If the avatar is the sparkle avatar, render the SparkleAvatar component
			return agent.profileURL === "sparkle" ? (
				<SparkleAvatar key={index}>
					{SPARKLE_ICON}
				</SparkleAvatar>
			) : (
			// Otherwise, render the StyledAvatar component
				<StyledAvatar
					key={index}
					alt={agent.name || "Agent"}
					src={agent.profileURL || ""}
				>
					{agent.profileURL ? null : agent.name ? agent.name.charAt(0) : "A"}
				</StyledAvatar>
			);
		});
	};

	return (
		<Avatars>
			{renderAvatars(agents)}
		</Avatars>
	);
};
export default AvatarStack;