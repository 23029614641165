import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BiSend } from "react-icons/bi";
import {
	Box,
	Stack,
	List,
	ListItemButton,
	ListItemText,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	IconButton,
	TextField
} from '@mui/material';
import useSettingsSelector from "./../../store/selectors/settings";
import AvatarStack from "./../../components/AvatarStack";

const Home = (props) => {
	const { faqs, handleFaq, setPage, agents } = props;
	const { HOME } = useSettingsSelector();
    
	return (
		<Stack direction="column" sx={{justifyContent: "space-between", minHeight: "274px", height: "100%"}}>
			<Box>
				<List sx={{
					borderRadius: '10px',
					margin: '15px 10px 10px 10px',
					background: "white",
					paddingTop: "0px",
					paddingBottom: "0px",
					boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
				}}>
					{faqs.map((option, index) => {
						return (
							<ListItemButton
								key={index}
								onClick={() => {
									handleFaq(option);
								}} >
								<ListItemText primary={option.question} />
								<KeyboardArrowRightIcon sx={{ color: HOME.LIST_BUTTON_COLOR }} />
							</ListItemButton>);
					})}
				</List>
			</Box>
			<Box
				sx={{padding: "10px"}}
				onClick={()=>{
					setPage('chat');
				}}
			>
				<Stack direction="column" spacing={2}>
					<Stack direction="row" sx={{alignItems: "center"}}>
						<AvatarStack
							agents={agents || []}
						/>
					</Stack>
					<Box id="default-prompt" sx={{background: "#FBFCFF"}}>
						<OutlinedInput
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="open chat screen"
										edge="end"
										color="primary"
										disabled={true}
									>
										<BiSend />
									</IconButton>
								</InputAdornment>
							}
							placeholder="Ask me anything"
							sx={{ width: "100%", borderRadius: "8px", height: "48px" }}
						/>
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
};

export default Home;