import React, {useEffect, useRef} from "react";
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import Sender from "./../../components/Sender";
import Reciever from "./../../components/Reciever/Reciever";
import AvatarStack from "./../../components/AvatarStack";

const History = ({query, records, agents, widget, contact, tenantId, handleRequest}) => {
	const messagesRef = useRef(null);

	function scrollBottom(behavior = "initial") {
		const scrollObject = { block: "end" };
		if (behavior !== "initial") scrollObject.behavior = behavior;
		setTimeout(() => messagesRef.current?.scrollIntoView(scrollObject), 100);
	}
	useEffect(()=>{
		scrollBottom("initial");
	}, [records]);

	return (
		<Stack spacing={1} className="chat-history" sx={{padding: "20px", maxHeight: "370px", minHeight: "370px", overflowY: "scroll"}}>
			<Box sx={{}}>
				<Stack sx={{
					alignItems: 'center',
					padding: "10px 0"
				}}>
					<AvatarStack 
						agents={agents || []}
					/>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
						AI agent answers instantly
					</Typography>
					<Typography variant="subtitle2">
						Ask for the team if needed
					</Typography>
				</Stack>
			</Box>
			{records && records.map((record, idx) => {
				return (
					<Box key={"history"+idx} sx={{margin: 0}}>
						{record.query && 
                            <Sender 
                            	txt={record.query}
                            	contact={contact}
                            />
						}
						{record.reply && 
                            <Reciever 
                            	reply={record.reply}
								widget={widget}
                            	contact={contact}
                            	agentId={record.reply.agentId}
                            	tenantId={tenantId}
                            	agents={agents}
                            	query={query}
                            	handleRequest={handleRequest}
                            />
						}
					</Box>
				);
			})}
			<div ref={messagesRef} />
		</Stack>
	);
};

export default History;