import React from "react";
import { Typography, Box } from "@mui/material";
import useSettingsSelector from "./../../store/selectors/settings";

const Message = ({content}) => {
	const { RECIEVER } = useSettingsSelector();
	const {message} = content;
	return (
		<Box sx={{margin: 0, backgroundColor: RECIEVER.BACKGROUND_COLOR, padding: "1rem", borderRadius: "12px", width: "fit-content"}}>
			<Typography>{message}</Typography>
		</Box>
	);
};

export default Message;