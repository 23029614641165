import React, { useState, useEffect } from "react";
import axios from "axios";
import { get } from "lodash";
import ProgressBar from "./../components/ProgressBar";
import UnVerifiedPage from "../pages/Unverified";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

const ProtectedRoute = (props) => {

	const { botId, tenantId, host } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [widget, setWidget] = useState(null);

	const fetchWidget = async (botId, tenantId) => {
		const response = await axiosInstance.get(`/widgets/${botId}?tenantId=${tenantId}`);
		const _widget = get(response, "data.data", null);
		if(_widget){
			setWidget(_widget);
			setIsLoading(false);
			_widget.domains.forEach(el => {
				const domain = el.domain;
				if(domain.includes(host)){
					setIsVerified(true);
				}
			});

            
		}
	};

	useEffect(() => {
		if (tenantId && botId) {
			fetchWidget(botId, tenantId);
		}
	}, [tenantId, botId]);

	if (isLoading) {
		return (
			<ProgressBar />
		);
	}

	const bootstrapChildren = () => {
		return React.cloneElement(props.children, {
			widget: widget,
		});    
	};
	return (
		<>
			{!isLoading && widget && (
				// <React.Fragment>
				// 	{bootstrapChildren()}
				// </React.Fragment>
				<>
					{isVerified && (
						<React.Fragment>
							{bootstrapChildren()}
						</React.Fragment>
					)}
					{!isVerified && (
						<UnVerifiedPage />
					)}
				</>
			)}
		</>
	);
};
export default ProtectedRoute;
